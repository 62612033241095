import { API } from "@project/shared"
export interface IReturnSelectionList {
  count?: number
  data?: any
  countAppliedJob?: number
}
export interface ISearchSelectionQuery {
  page: string
  size: string
  keyword: string
  applicant_status?: string
  applicant_name?: string
  person_in_charge?: string
  agent_company_id?: string
  query_from?: string
  applicant_id?: string
}

export const fetchSelection = async ({
  queryKey,
}): Promise<IReturnSelectionList> => {
  const params = {} as ISearchSelectionQuery
  params.page = queryKey[1].page
  params.query_from = queryKey[1].queryFrom
  params.size = queryKey[1].size
  params.keyword = queryKey[1].keyword
  params.applicant_status = queryKey[1].applicantStatus
  params.applicant_name = queryKey[1].applicantName
  params.person_in_charge = queryKey[1].personInCharge
  params.agent_company_id = queryKey[1].agentCompanyId
  params.applicant_id = queryKey[1].applicantId
  return API.get(`/job-applicants`, { params })
}

export const getSelection = async (id) => {
  return API.get(`/job-applicants/${id}`)
}

export const getMemberNamesWithId = async (id) => {
  return API.get(`/job-applicants/names/${id}`)
}

export const addSelection = async (values): Promise<any> => {
  return API.post(`/job-applicants`, values)
}

export const updateSelection = async ({ id, values }) => {
  return API.put(`/job-applicants/${id}`, { status: values })
}

export const updateSelectionReason = async ({ id, status, comments }) => {
  return API.put(`/job-applicants/${id}`, {
    status: status,
    comments: comments,
  })
}

export const deleteSelection = async (id) => {
  return API.delete(`/job-applicants/${id}`)
}

export const applicationStatusCountForAgent = async ({ queryKey }) => {
  const params = {} as ISearchSelectionQuery
  params.query_from = queryKey[1].queryFrom
  params.keyword = queryKey[1].keyword
  params.applicant_status = "!=pickup"
  params.applicant_name = queryKey[1].applicantName
  params.applicant_id = queryKey[1].applicantId
  params.person_in_charge = queryKey[1]?.personInCharge
  params.agent_company_id = queryKey[1]?.agentCompanyId
  return API.get(`/job-applicant-status-list-agent`, { params })
}

export const applicationStatusCountForAgentCompany = async ({ queryKey }) => {
  const params = {} as ISearchSelectionQuery
  params.query_from = queryKey[1].queryFrom
  params.keyword = queryKey[1].keyword
  params.applicant_status = "!=pickup"
  params.applicant_name = queryKey[1].applicantName
  params.applicant_id = queryKey[1].applicantId
  params.person_in_charge = queryKey[1]?.personInCharge
  params.agent_company_id = queryKey[1]?.agentCompanyId
  return API.get(`/job-applicant-status-list-agent-company`, { params })
}
