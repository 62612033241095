import { API } from "@project/shared"

export const updateSettings = async (values) => {
  const id = values.id
  return API.put(`/agent-members/${id}`, values)
}

export const getSettings = async (uid) => {
  return API.get(`/agent-members/${uid}`, { params: { is_fb_uid: true } })
}
