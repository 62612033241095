import { API } from "@project/shared"

interface EventRequestParams {
  calendarId: string
  singleEvents: boolean
  orderBy: string
  maxResults: number
  timeMin?: string
  timeMax?: string
  nextPageToken?: string
  q?: string
  showDeleted?: boolean
  timeZone?: string
}

export const addEvent = async (values) => {
  const response = await window["gapi"]?.client?.calendar?.events.insert({
    calendarId: "primary",
    conferenceDataVersion: values.hasMeetLink ? 1 : 0,
    sendUpdates: "all",
    resource: {
      end: {
        dateTime: values.endDate,
      },
      start: {
        dateTime: values.startDate,
      },
      attendees:
        values?.participants?.length &&
        values?.participants?.map((item) => {
          return {
            email: item.email,
          }
        }),
      reminders: {
        useDefault: values.remainder == false ? true : false,
        overrides:
          values.remainder == true
            ? [
                {
                  method: "email",
                  minutes: values.time,
                },
              ]
            : null,
      },
      extendedProperties: {
        shared: {
          organizer: values.organizer,
          company_name: values.company_name,
        },
      },
      description: values.description,
      conferenceData: {
        createRequest: {
          conferenceSolutionKey: {
            type: "hangoutsMeet",
          },
          // To create a meet link everytime u need to pass random string
          requestId: "some-random-string",
        },
      },
      summary: values.summary,
    },
  })
  return response
}

export const fetchEvents = async ({ queryKey }) => {
  const minTime = queryKey[1]
  const maxTime = queryKey[2]
  const searchQuery = queryKey[3]

  const queryParams: EventRequestParams = {
    calendarId: "primary",
    singleEvents: true,
    orderBy: "startTime",
    maxResults: 2500,
    q: searchQuery,
  }

  minTime && (queryParams.timeMin = minTime)
  maxTime && (queryParams.timeMax = maxTime)
  const response = await window["gapi"]?.client?.calendar?.events.list({
    ...queryParams,
  })
  return response
}

export const getEvent = async (eventId) => {
  const response = await window["gapi"]?.client?.calendar?.events.get({
    calendarId: "primary",
    eventId,
  })
  return response
}

export const updateEvent = async (values) => {
  const participants =
    values?.participants?.length &&
    values.participants.map((item) => {
      return {
        email: item.email,
      }
    })
  const response = await window["gapi"]?.client?.calendar?.events.update({
    calendarId: "primary",
    eventId: values.eventId,
    sendUpdates: "all",
    conferenceDataVersion: values.hasMeetLink ? 1 : 0,
    resource: {
      end: {
        dateTime: values.endDate,
      },
      start: {
        dateTime: values.startDate,
      },
      attendees: participants,
      reminders: {
        useDefault: values.remainder == false ? true : false,
        overrides:
          values.remainder == true
            ? [
                {
                  method: "email",
                  minutes: values.time,
                },
              ]
            : null,
      },
      extendedProperties: {
        shared: {
          organizer: values.organizer,
          company_name: values.company_name,
        },
      },
      description: values.description,
      conferenceData: {
        createRequest: {
          conferenceSolutionKey: {
            type: "hangoutsMeet",
          },
          // To create a meet link everytime u need to pass random string
          requestId: "some-random-string",
        },
      },
      summary: values.summary,
    },
  })
  return response
}

export const sendReminder = async (values) => {
  return API.post(`/meeting`, values)
}
