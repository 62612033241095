import { API } from "@project/shared"
export interface IReturnJobSeekerList {
  count?: number
  data?: any
}

interface ISearchJobSeekerQuery {
  page: string
  size: string
  keyword: string
  status?: string
  agent_member_id?: string
  all?: number
}

export const fetchJobSeeker = async ({
  queryKey,
}): Promise<IReturnJobSeekerList> => {
  const params = {} as ISearchJobSeekerQuery
  params.page = queryKey[1].page
  params.size = queryKey[1].size
  params.keyword = queryKey[1].keyword
  params.agent_member_id = queryKey[1].agentMemberId
  params.all = queryKey[1].all
  return API.get(`/applicants/`, { params })
}

export const fetchJobSeekerStatusCount = async ({
  queryKey,
}): Promise<IReturnJobSeekerList> => {
  const params = {} as ISearchJobSeekerQuery
  params.agent_member_id = queryKey[1].agentMemberId
  params.all = queryKey[1].all
  return API.get(`/status/count`, { params })
}

export const getJobSeeker = async (id) => {
  return API.get(`/applicants/${id}`)
}

export const addJobSeeker = async (values): Promise<any> => {
  return API.post(`/applicants`, values)
}

export const updateJobSeeker = async (values) => {
  const id = values.id
  return API.put(`/applicants/${id}`, values)
}

export const patchJobSeeker = async (values) => {
  const id = values.id
  return API.patch(`/applicants/${id}`, values)
}

export const deleteJobSeeker = async (id) => {
  return API.delete(`/applicants/${id}`)
}
