import { API } from "@project/shared"

export interface IReturnJobList {
  count?: number
  data?: any
}
interface ISearchJobQuery {
  page?: string
  size?: string
  card_view?: boolean
  company_id?: string
  agent_company_id?: string
  keyword?: string
  savedJob?: boolean
  job_type?: string[]
  agent_id?: string
  industry_type_new?: string[]
  occupation_type_new?: string[]
  area?: string[]
  annual_income?: {
    from?: string
    to?: string
  }
  age?: {
    from?: string
    to?: string
  }
  education_history?: []
  topic?: {
    recruitment_features?: string[]
    workplace_features?: string[]
    work_features?: string[]
    company_features?: string[]
    characteristics?: string[]
    thoughtfulness?: string[]
    company_type?: string[]
    working_type?: string[]
  }
  sales_agent_recommendation?: boolean
  free_words?: {
    condition?: string
    keywords?: string
  }[]
  logic?: "and" | "or"
}

export const fetchPublicJobs = async ({
  queryKey,
}): Promise<IReturnJobList> => {
  const params = {} as ISearchJobQuery
  params.page = queryKey[1].page
  params.size = queryKey[1].size
  params.card_view = queryKey[1]?.card_view
  params.company_id = queryKey[1]?.company_id
  params.agent_company_id = queryKey[1]?.agent_company_id
  params.keyword = queryKey[1].keyword
  params.savedJob = queryKey[1].savedJob
  params.agent_id = queryKey[1].agent_id
  params.job_type = queryKey[1].job_type?.join() || ""
  params.industry_type_new = queryKey[1]?.industry_type_new?.join() || ""
  params.occupation_type_new = queryKey[1]?.occupation_type_new?.join() || ""
  params.area = queryKey[1].area.join() || ""
  params.annual_income = queryKey[1].annual_income
  params.age = queryKey[1].age
  params.education_history = queryKey[1].education_history?.join() || ""
  params.topic = queryKey[1].topic
  params.sales_agent_recommendation = queryKey[1].sales_agent_recommendation
  params.free_words = queryKey[1].free_words
  params.logic = queryKey[1].logic
  if (queryKey[1].savedJob) {
    return API.get(`/favorite-job`, { params })
  }
  return API.get(`/all-jobs`, { params })
}

export const fetchJobs = async ({ queryKey }): Promise<IReturnJobList> => {
  const params = {} as ISearchJobQuery
  params.page = queryKey[1].page || 1
  params.size = queryKey[1].size
  params.keyword = queryKey[1].keyword
  params.company_id = queryKey[1]?.company_id
  params.agent_id = queryKey[1].agent_id
  params.job_type = queryKey[1]?.job_type?.join() || ""
  params.industry_type_new = queryKey[1]?.industry_type_new?.join() || ""
  params.occupation_type_new = queryKey[1]?.occupation_type_new?.join() || ""
  params.area = queryKey[1]?.area?.join() || ""
  params.annual_income = queryKey[1].annual_income
  params.age = queryKey[1].age
  params.education_history = queryKey[1]?.education_history?.join() || ""
  params.topic = queryKey[1].topic
  params.sales_agent_recommendation = queryKey[1].sales_agent_recommendation
  params.free_words = queryKey[1].free_words
  params.logic = queryKey[1].logic
  return API.get(`/jobs`, { params })
}

export const getJob = async (id: string) => {
  return API.get(`/jobs/${id}`)
}

export const getPublicJob = async (id: string) => {
  return API.get(`/all-jobs/${id}`)
}
export const addJob = async (values: any) => {
  return API.post(`/jobs`, values)
}

export const updateJob = async (values: any) => {
  const id = values.id
  return API.put(`/jobs/${id}`, values)
}

export const deleteJob = async (id: string) => {
  return API.delete(`/jobs/${id}`)
}

export const jobApproval = async (values) => {
  const id = values.id
  return API.put(`/approve-jobs/${id}`, values)
}

export const jobApprovalEdit = async (values) => {
  const id = values.id
  return API.put(`/admin-edit-jobs/${id}`, values)
}

export const jobCount = async () => {
  return API.get(`/job-count`)
}

export const fetchAllPublicJobs = async (): Promise<IReturnJobList> => {
  return API.get(`/all-jobs?size=Infinity`)
}
