import { API } from "@project/shared"
export interface IReturnCompanyList {
  count?: number
  data?: any
}

interface ISearchCompanyQuery {
  page?: string
  size?: string
  keyword: string
  agent_member_id?: string
  all?: number
}

interface ISearchAllCompanyQuery {
  agent_member_id?: string
  agentId?: string
  size?: string
}

export const fetchCompany = async ({
  queryKey,
}): Promise<IReturnCompanyList> => {
  const params = {} as ISearchCompanyQuery
  params.page = queryKey[1].page
  params.size = queryKey[1].size
  params.keyword = queryKey[1].keyword
  params.agent_member_id = queryKey[1].agentMemberId
  params.all = queryKey[1].all
  return API.get(`/agent-companies`, { params })
}

export const getCompany = async (id) => {
  return API.get(`/agent-companies/${id}`)
}

export const addCompany = async (values): Promise<any> => {
  values.reward_amount = Number(values.reward_amount)
  return API.post(`/agent-companies`, values)
}

export const updateCompany = async (values) => {
  const id = values.id
  values.reward_amount = Number(values.reward_amount)
  return API.put(`/agent-companies/${id}`, values)
}

export const deleteCompany = async (id) => {
  return API.delete(`/agent-companies/${id}`)
}

export const fetchAllCompany = async ({
  queryKey,
}): Promise<IReturnCompanyList> => {
  const params = {} as ISearchAllCompanyQuery
  params.agent_member_id = queryKey[1].agentMemberId
  params.size = queryKey[1].size
  params.agentId = queryKey[1].agentId
  return API.get(`/agent-companies/all`, { params })
}
