import { API } from "@project/shared"
import { CompanyType } from "../utils"
interface IStatusFilter {
  date_from?: string
  date_to?: string
  referrer_id?: string
  agent_company_id?: string
  company_id?: string
}
interface ICompanyRankingFilter {
  months?: string
}

interface IStatusDayCount {
  applicant_id?: string
  job_id?: string
  status_from?: string
  status_to?: string
}

const fetchStatusCount = async ({ queryKey }) => {
  const params = {} as IStatusFilter
  params.date_from = queryKey[1].from
  params.date_to = queryKey[1].to
  params.referrer_id = queryKey[1].staff
  params[
    queryKey[1].type === CompanyType.COMPANY ? "company_id" : "agent_company_id"
  ] = queryKey[1].company
  return API.get(
    queryKey[1].graph === "last-status-graph"
      ? `/last-status/count`
      : `/status/count`,
    {
      params,
    }
  )
}

const fetchJobOfferCount = async ({ queryKey }) => {
  const params = {} as IStatusFilter
  params.date_from = queryKey[1].from
  params.date_to = queryKey[1].to
  params.referrer_id = queryKey[1].staff
  return API.get(`/job-offer/count`, {
    params,
  })
}

const fetchCompanyRanking = async ({ queryKey }) => {
  const params = {} as ICompanyRankingFilter
  params.months = queryKey[1]
  // return {
  //   data: [
  //     {
  //       count: 2,
  //       company_name: "株式会社わた企業",
  //       ranking: 1,
  //     },
  //     {
  //       count: 1,
  //       company_name: "Test comp",
  //       ranking: 2,
  //     },
  //     {
  //       count: 1,
  //       company_name: "Cameran Vasquez",
  //       ranking: 2,
  //     },
  //   ],
  // }
  return API.get(`introduction/count`, {
    params,
  })
}

const statusDayCount = async ({ queryKey }) => {
  const params = {} as IStatusDayCount
  params.applicant_id = queryKey[1]?.job_seeker
  params.job_id = queryKey[1]?.job?.join() || ""
  params.status_from = queryKey[1]?.status_from
  params.status_to = queryKey[1]?.status_to
  return API.get(`status/day/count`, {
    params,
  })
}

export {
  fetchCompanyRanking,
  fetchJobOfferCount,
  fetchStatusCount,
  statusDayCount,
}
